@import "../../variables.scss";

.sneak-content {
  display: block;
  flex: 0 0 calc(100% - $sidebar-width);
  overflow: hidden;
  margin-top: $spacing-1;
}

.big-img {
  img {
    width: 100%;
    border-radius: $border-radius;
    margin-bottom: $spacing-1;
  }
}

.masonry-sneak-grid {
  display: flex;
  margin-left: -$spacing-1; /* gutter size offset */
  width: auto;

  img {
    width: 100%;
    border-radius: $border-radius;
  }
}

.masonry-column {
  padding-left: $spacing-1; /* gutter size */
  background-clip: padding-box;
}

.vimeo-player {

  iframe {
    border-radius: $border-radius;
  }
}

.item {
  background-color: $card-color;
  border-radius: $border-radius;
  padding: $spacing-2;
  box-sizing: border-box;
  height: fit-content;
  margin-bottom: $spacing-1;
  white-space: pre-wrap;

  h1 {
      margin-bottom: $spacing-2;
  }

  img {
    width: 100%;
    border-radius: $border-radius;
    margin-top: $spacing-2;
  }
}


.project-information-container {

  display: flex;
  flex-direction: row;
  margin-bottom: $spacing-3;

  h2 {
    margin-bottom: $spacing-1;
  }

  .item-desc {
    background-color: $card-color;
    border-radius: $border-radius;
    padding: $spacing-2;
    box-sizing: border-box;
    height: fit-content;
    width: 66%;
    margin-bottom: $spacing-1;
    margin-right: $spacing-1;
    white-space: pre-wrap;
  }
  
  .item-info {
    background-color: $card-color;
    border-radius: $border-radius;
    padding: $spacing-2;
    box-sizing: border-box;
    height: fit-content;
    width: 33%;
    margin-bottom: $spacing-1;
    white-space: pre-wrap;
  }
}

@media only screen and (min-width: 2150px) {

  .project-information-container {
    p {
      font-size: 1rem;
    }
  }
}
