@import "../../variables.scss";

.sneak-content {
  display: block;
  flex: 0 0 calc(100% - $sidebar-width);
  overflow: hidden;
  margin-top: $spacing-1;
}

.big-img {
  img {
    width: 100%;
    border-radius: $border-radius;
    margin-bottom: $spacing-1;
  }
}

.masonry-sneak-grid {
  display: flex;
  margin-left: -$spacing-1; /* gutter size offset */
  width: auto;

  img {
    width: 100%;
    border-radius: $border-radius;
  }
}

.masonry-column {
  padding-left: $spacing-1; /* gutter size */
  background-clip: padding-box;
}

.vimeo-player {

  iframe {
    border-radius: $border-radius;
  }
}

.magazine-project-information-container {

  display: grid;

  grid-template-columns:1fr 1fr 1fr;
  grid-template-rows:1fr;

  flex-direction: row;
  margin-bottom: $spacing-3;

  h2 {
    margin-bottom: $spacing-1;
  }

  .item-desc {
    grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 1;

    background-color: $card-color;
    border-radius: $border-radius;
    padding: $spacing-2;
    box-sizing: border-box;
    height: fit-content;
    //width: 66%;
    margin-bottom: $spacing-1;
    margin-right: $spacing-1;
    white-space: pre-wrap;
  }
  
  .item-info {
    grid-column-start: 3;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 1;


    background-color: $card-color;
    border-radius: $border-radius;
    padding: $spacing-2;
    box-sizing: border-box;
    height: fit-content;
    //width: 33%;
    margin-bottom: $spacing-1;
    white-space: pre-wrap;
  }

  .external-link {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 800px) {
  .sneak-content {
    flex: 0 0 100%;
  }

  .magazine-project-information-container {

    display: grid;
    grid-template-columns:1fr;
    grid-template-rows:1fr;

    margin-bottom: $spacing-3;
  
    h2 {
      margin-bottom: $spacing-1;
    }
  
    .item-desc {

      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 1;
      width: 100%;

     
    }
    
    .item-info {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 2;
      grid-row-end: 2;


    
    }
  
    .external-link {
      text-decoration: underline;
    }
  }

}