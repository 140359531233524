$spacing-1: 0.5rem; // 8px
$spacing-2: 0.75rem; //  12px
$spacing-3: 5rem; //80px
$spacing-4: 2vh;

$border-radius: 0.7rem; //8px

$sidebar-width: 22.5rem; //360px

$card-color: #e9e9e9;
$new-color: #f2f2f2;
$card-color-hover: #e0e0e0;


@font-face {
    font-family: antarctica600;
    font-weight: 600;
    font-style: normal;
    src: url(../public/assets/font/Antarctica-VF.ttf);
}
@font-face {
    font-family: antarctica400;
    font-weight: 400;
    font-style: normal;
    src: url(../public/assets/font/Antarctica-VF.ttf);
}

$font-family-p: url(../public/assets/font/Antarctica-600.ttf);
$font-size-p: 0.75rem; // 12px
$font-size-h2: 0.7813rem; // 12.5px