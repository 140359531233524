@import "../../variables.scss";

.sneak-content {
  display: block;
  flex: 0 0 calc(100% - $sidebar-width);
  overflow: hidden;
  margin-top: $spacing-1;
  margin-bottom: 50px;
}

.big-img {
  img {
    width: 100%;
    border-radius: $border-radius;
    margin-bottom: $spacing-1;
  }
}

.my-masonry-grid {
  display: flex;
  margin-left: -$spacing-1; /* gutter size offset */
  width: auto;

  img {
    width: 100%;
    border-radius: $border-radius;
  }
}

.my-masonry-grid_column {
  padding-left: $spacing-1; /* gutter size */
  background-clip: padding-box;
}

.item {
  background-color: $card-color;
  border-radius: $border-radius;
  padding: $spacing-2;
  box-sizing: border-box;
  height: fit-content;
  margin-bottom: $spacing-1;
  white-space: pre-wrap;

  h1 {
      font-family: antarctica600;
      margin-bottom: $spacing-2;
  }

  h3 {
    font-family: antarctica400;
    margin-top: $spacing-1;
  }

  img {
    width: 100%;
    border-radius: $border-radius;
    margin-top: $spacing-2;
  }
  .video-element {
    margin-top: $spacing-2;
    margin-bottom: $spacing-1/2;
  }
}

.vimeo-player {

  iframe {
    border-radius: $border-radius;
  }
}

.video-element {
  margin-bottom: -$spacing-1;
}

.project-information-container {

  display: flex;
  flex-direction: row;
  margin-bottom: $spacing-3;

  h2 {
    margin-bottom: $spacing-1;
  }

  .item-desc {
    background-color: $card-color;
    border-radius: $border-radius;
    padding: $spacing-2;
    box-sizing: border-box;
    height: fit-content;
    width: 66%;
    margin-bottom: $spacing-1;
    margin-right: $spacing-1;
    white-space: pre-wrap;
  }
  
  .item-info {
    background-color: $card-color;
    border-radius: $border-radius;
    padding: $spacing-2;
    box-sizing: border-box;
    height: fit-content;
    width: 33%;
    margin-bottom: $spacing-1;
    white-space: pre-wrap;
  }

  .external-link {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 800px) {
  .sneak-content {
    flex: 0 0 100%;
  }

  
}