@import "../../variables.scss";

.sidebar-wrapper {
  flex: 0 0 22rem;
  height: 100vh;
  overflow-y: auto;
  position: -webkit-sticky;
  position: sticky;
  scrollbar-width: 0;
  top: 0;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  padding-top: $spacing-1;
  margin-right: $spacing-1;

  .corner-container {
    margin: 1.875rem 0;

    .logo-container {
      padding: 1.875rem;

      .Nine-Seven {
        display: block;
        margin: auto;
        width: 200px;
        height: auto;
      }
    }
    .info {
      margin-top: 1.875rem;
      text-align: center;
      font-family: $font-family-p;
    }
  }

  .tile {
    background-color: $card-color;
    border-radius: $spacing-1;
    padding: 0.8125rem 0.75rem;
    margin-bottom: $spacing-1;

    .title-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: $spacing-2;
    }

    .content {
      display: flex;

      img {
        object-fit: cover;
        object-position: center center;
        width: 3.75rem;
        height: 3.75rem;
        border-radius: $border-radius;
        margin-right: $spacing-2;
      }
    }
  }

  .hidden {
    display: none;
  }

  .tile:hover {
    background-color: $card-color-hover;
  }
}

.mobile-bottom-bar {
  display: none;
  
}

.sidebar-wrapper::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
  
}

@media only screen and (max-width: 800px) {
  .sidebar-wrapper {
    display: none;
  }

  .overlay-bottom-bar {
    position: fixed;
    background: linear-gradient(90deg, rgba(0,0,0,0) 60%, #ffffff 100%);
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 15vh;
    pointer-events: none;
    
  }

  .mobile-bottom-bar {
    display: inherit;
    position: fixed;
    z-index: 5;
    background-color: $card-color 100%;
    backdrop-filter: blur(80px);
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 15vh;
    overflow-x: auto;
    
    
    .icons-wrapper {
      display: flex;
      align-items: center; // Hier setzen wir align-items auf center, um die Vertikale Ausrichtung zu zentrieren
      height: 100%; // Ändere die Höhe auf 100%
      padding-left: 8px;
      
      .logo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: $spacing-4;

        .active-dot {
          height: 5px;
          width: 5px;
          background-color: #c4c4c4;
          border-radius: 50%;
          margin-top: $spacing-1;
        }
      }

      .logo-bottom-bar {
        height: 100%; // Setze die Höhe auf 100% der .mobile-bottom-bar
        width: calc(11vh - 8px); // Berechne die Breite relativ zur Höhe der .mobile-bottom-bar und subtrahiere 8px (für padding)        
      }
    }
  }
}



