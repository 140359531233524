@import "./variables.scss";

body {
  margin: 0;
  font-family: antarctica400, BlinkMacSystemFont, 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 8px 0 8px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#page-wrapper {
  display: flex;
}

a {
  text-decoration: none;
  color: black;
}

p {
  margin: 0;
  color: black;
  font-size: $font-size-p;
}

b {
  color: black;
}

h1 {
  font-family: antarctica600;
  margin: 0;
}

h2 {
  font-family: antarctica600;
  font-size: $font-size-h2;
  margin-top: 0;
  margin-bottom: $spacing-1/2;
  color: black;
  
}


.video-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .video-element {
    width: 50%;
    margin-bottom: $spacing-1;
    .skeleton {
      width: 100%;
      height: 100%;
      background-color: $card-color;
      border-radius: $border-radius;
      animation: colorTransition 1s infinite;
    }
  }
  .big-video-element {
    width: 100%;
    margin-bottom: $spacing-1;
    .skeleton {
      width: 100%;
      height: 100%;
      background-color: $card-color;
      border-radius: $border-radius;
      animation: colorTransition 1s infinite;
    }
  }

  .video-element:first-child {
    width: calc(50% - $spacing-1 / 2);
  }

  .video-element:last-child {
    width: calc(50% - $spacing-1 / 2);
  }
}

.big-img {
  img {
    display: flex;
    width: 100%;
    border-radius: $border-radius;
    margin-bottom: -$spacing-1;
  }
}

.two-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  img {
    border-radius: $border-radius;
    width: 50%;
    margin-bottom: $spacing-1;
  }

  img:first-child {
    width: calc(50% - $spacing-1 / 2);
  }

  img:last-child {
    width: calc(50% - $spacing-1 / 2);
  }

  .video-element {
    width: calc(50% - $spacing-1 / 2);
  }
}


@keyframes colorTransition {
  0% {
    background-color: $card-color;
  }
  50% {
    background-color: $new-color;
  }
  100% {
    background-color: $card-color;
  }
}




@media only screen and (max-width: 800px) {
  .video-wrapper {
    //flex-direction: column;
    .video-element:first-child {
      width: 100%;
    }
  
    .video-element:last-child {
      width: 100%;
    }

    .video-element {
      .skeleton {
        width: 100%;
        height: 100%;
        background-color: $card-color;
        border-radius: $border-radius;
      }
    }
  }

  .two-images {

    img {
      width: 100%;
    }
  
    img:first-child {
      width: 100%;
    }
  
    img:last-child {
      width: 100%;
    }
  
    .video-element {
      width: 100%;
    }
  }
  
}

#landing-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  img {
    width: 9rem;
  }
}